const filters = {
    methods: {
        formatFileSize(size) {
            size = Number(size)
            if (size > 1024 * 1024 * 1024 * 1024) {
                return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + " TB"
            } else if (size > 1024 * 1024 * 1024) {
                return (size / 1024 / 1024 / 1024).toFixed(2) + " GB"
            } else if (size > 1024 * 1024) {
                return (size / 1024 / 1024).toFixed(2) + " MB"
            } else if (size > 1024) {
                return (size / 1024).toFixed(2) + " KB"
            }
            return size.toString() + " B"
        },
        formatPhoneNumber(number) {
            return number
                .replace(/(\+\d{2})(\d{3})(\d*)/, "$1 $2 $3")
                .replace(new RegExp("^(00)"), "+")
        },
    },
}

export default filters
