
import Button from "@/components/base/Button"
import rules from "@/mixins/rules"
import { mdiMenuDown } from "@mdi/js"

export default {
    name: "PhoneNumber",
    components: { Button },
    mixins: [rules],
    events: ["input"],
    props: {
        /** Includes country prefix on input change */
        includePrefix: {
            type: Boolean,
            default: false,
        },
        /** Available countries */
        countries: {
            type: Array,
            default: () => [
                {
                    name: "Deutschland",
                    code: "DE",
                    prefix: "+49",
                    icon: "🇩🇪",
                    displayPrefix: "+49",
                },
                {
                    name: "Österreich",
                    code: "AT",
                    prefix: "+43",
                    icon: "🇦🇹",
                    displayPrefix: "+43",
                },
                {
                    name: "Schweiz",
                    code: "CH",
                    perfix: "+41",
                    icon: "🇨🇭",
                    displayPrefix: "+41",
                },
            ],
        },
        /** Enforces only phone number inputs */
        strict: {
            type: Boolean,
            default: true,
        },
        /** Hides country selector */
        hideCountries: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        mdiMenuDown,
        isMenuOpen: false,
        phoneNumber: "",
        country: {},
        displayPrefix: null,
    }),
    computed: {
        availableCountries() {
            return this.countries.filter((c) => c.name !== this.country.name)
        },
        inputRules() {
            if (this.strict) return [this.ruleRequired(), this.ruleNumbersOnly]
            return [
                this.ruleRequired(),
                this.or(this.ruleEmail, this.rulePhone),
            ]
        },
        /** External attrs override defaults */
        inputAttrs() {
            return {
                label: "Telefonnummer",
                type: "tel",
                name: "phone",
                placeholder: "XXX XXXX XXX",
                "persistent-placeholder": true,
                "validate-on-blur": true,
                ...this.$attrs,
            }
        },
    },
    created() {
        if (this.countries?.length > 0) {
            this.country = this.countries[0]
            this.displayPrefix = this.country.displayPrefix
        }
    },
    methods: {
        setCountry(country) {
            this.country = country
            this.displayPrefix = this.country.displayPrefix
            if (this.includePrefix) {
                this.$emit("input", country.prefix + this.phoneNumber)
            }
        },
        onPhoneNumberChange(newVal) {
            let cleanedVal = newVal
            if (this.strict) {
                newVal = newVal.replace(/[^0-9 -]/g, "") // Only numbers, spaces, dashes
                newVal = newVal.replace(/([ -])\1+/g, "$1") // Allow only single consecutive spaces or dashes
                cleanedVal = newVal.replace(/[ -]/g, "") // Without spaces and dashes
            }

            this.$nextTick(() => (this.phoneNumber = newVal))
            if (this.includePrefix && cleanedVal) {
                const fullNumber =
                    this.country.prefix + cleanedVal.replace(/^0+/, "") // Remove leading zeros after country code
                this.$emit("input", fullNumber)
            } else {
                this.$emit("input", cleanedVal)
            }
        },
    },
}
