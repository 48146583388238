
export default {
    name: "CodeInput",
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        length: {
            type: Number,
            default: 4,
        },
    },
    data: () => ({
        emittedCode: null,
    }),
    methods: {
        onFinish(code) {
            this.emitCode(code)
        },
        onInput(input) {
            if (input && input.length === this.length) {
                this.emitCode(input)
            } else if (input && input.length < this.length) {
                this.emittedCode = null
            }
        },
        emitCode(code) {
            if (code !== this.emittedCode) {
                this.emittedCode = code
                this.$emit("change", code)
            }
        },
    },
}
