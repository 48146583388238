
import Button from "@/components/base/Button"
import { mdiChevronLeft } from "@mdi/js"

export default {
    name: "AuthFormHeader",
    components: { Button },
    data: () => ({
        mdiChevronLeft,
    }),
}
