
import Button from "@/components/base/Button"
import { mdiEmail, mdiPhone } from "@mdi/js"
import authService from "@/services/auth.service"

export default {
    name: "AuthButtons",
    components: { Button },
    props: {
        showEmail: {
            default: true,
            type: Boolean,
        },
        showPhone: {
            default: false,
            type: Boolean,
        },
        showFacebook: {
            type: Boolean,
            default: true,
        },
        showGoogle: {
            type: Boolean,
            default: true,
        },
        showApple: {
            type: Boolean,
            default: true,
        },
        socialRedirectUrl: {
            type: String,
            default: undefined,
        },
    },
    data: () => ({
        mdiEmail,
        mdiPhone,
    }),
    methods: {
        socialLogin(service) {
            this.$oabalytics.trackEvent("social_login", {
                provider: service,
            })
            if (!this.$auth.$storage.getCookie("redirect_url")) {
                this.$auth.$storage.setCookie(
                    "redirect_url",
                    this.socialRedirectUrl || this.$route.fullPath
                )
            }
            authService.getSocialUrl(service).then((url) => {
                window.location.href = url
            })
        },
    },
}
