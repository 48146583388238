
import ValidationResponse from "@/components/base/api/ValidationResponse"
import Button from "@/components/base/Button"
import ProgressLinear from "@/components/base/ProgressLinear"
import authService from "@/services/auth.service"
import CodeInput from "@/components/base/input/CodeInput"
import AuthFormHeader from "@/components/auth/AuthForm/AuthFormHeader"
import filters from "@/mixins/filters"

export default {
    name: "VerifyPhoneNumberForm",
    components: {
        AuthFormHeader,
        CodeInput,
        ValidationResponse,
        Button,
        ProgressLinear,
    },
    mixins: [filters],
    props: {
        phoneNumber: {
            type: String,
            required: true,
        },
        length: {
            type: Number,
            default: 5,
        },
        polite: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        validationData: null,
        isLoadingRequest: false,
        isLoadingNewCode: false,
        isNewCodeButtonEnabled: true,
    }),
    computed: {
        headline() {
            const formattedPhoneNumber = this.formatPhoneNumber(
                this.phoneNumber
            )
            const du =
                "Wir haben Dir gerade eine SMS mit dem Bestätigungscode an " +
                formattedPhoneNumber +
                " geschickt. Bitte trage den Code ein, um fortzufahren."
            const sie =
                "Wir haben Ihnen gerade eine SMS mit dem Bestätigungscode an " +
                formattedPhoneNumber +
                "  geschickt. Bitte tragen Sie den Code ein, um fortzufahren."
            return this.polite ? sie : du
        },
    },
    methods: {
        requestNewCode() {
            this.validationData = null
            this.isLoadingNewCode = true
            this.isNewCodeButtonEnabled = false
            authService
                .requestOTP(this.phoneNumber)
                .then((response) => {
                    this.validationData = response
                    setTimeout(() => {
                        this.isNewCodeButtonEnabled = true
                    }, 30000)
                })
                .catch((error) => {
                    this.validationData = error.response.data
                    this.isNewCodeButtonEnabled = true
                })
                .finally(() => {
                    this.isLoadingNewCode = false
                })
        },
        validateCode(code) {
            this.validationData = null
            this.isLoadingRequest = true

            authService
                .verifyOTP(this.phoneNumber, code)
                .then(() => this.$emit("success", code))
                .catch((error) => {
                    this.validationData = error.response.data
                    console.error(
                        "ERROR @ Register Code Verification ",
                        error.response
                    )
                })
                .finally(() => (this.isLoadingRequest = false))
        },
    },
}
