
import rules from "@/mixins/rules"
import Button from "@/components/base/Button"
import ValidationResponse from "@/components/base/api/ValidationResponse"
import { mdiEye, mdiEyeOff } from "@mdi/js"
import AuthFormHeader from "@/components/auth/AuthForm/AuthFormHeader"

export default {
    name: "RegistrationForm",
    components: { AuthFormHeader, ValidationResponse, Button },
    mixins: [rules],
    props: {
        passwordRequired: {
            type: Boolean,
            default: false,
        },
        emailRequired: {
            type: Boolean,
            default: true,
        },
        firstnameRequired: {
            type: Boolean,
            default: true,
        },
        lastnameRequired: {
            type: Boolean,
            default: true,
        },
        errorData: {
            type: Object,
            default: null,
        },
        isRegisterButtonDisabled: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            firstname: null,
            lastname: null,
            email: null,
            password: null,
            confirmNewsletter: false,
            showPassword: false,
            mdiEye,
            mdiEyeOff,
        }
    },
    methods: {
        register() {
            if (this.$refs.registrationForm.validate()) {
                this.$emit("success", {
                    email: this.email,
                    firstname: this.firstname,
                    lastname: this.lastname,
                    password: this.password,
                    newsletter_confirmed: this.confirmNewsletter,
                })
            }
        },
    },
}
