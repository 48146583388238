
import Button from "@/components/base/Button"
import ValidationResponse from "@/components/base/api/ValidationResponse"
import AuthFormHeader from "@/components/auth/AuthForm/AuthFormHeader"

import rules from "@/mixins/rules"
import { mdiEye, mdiEyeOff } from "@mdi/js"

export default {
    name: "EmailLoginForm",
    components: {
        Button,
        ValidationResponse,
        AuthFormHeader,
    },
    mixins: [rules],
    props: {
        errorData: {
            type: Object,
            default: null,
        },
    },
    data: () => ({
        password: "",
        showPassword: false,
        mdiEye,
        mdiEyeOff,
    }),
    mounted() {
        this.$refs.password.focus()
    },
    methods: {
        login() {
            if (this.$refs.form.validate()) {
                this.$emit("success", this.password)
            }
        },
        forgotPassword() {
            this.$emit("password-forgotten")
        },
    },
}
