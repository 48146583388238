
import authService from "@/services/auth.service"
import CodeInput from "@/components/base/input/CodeInput"
import Button from "@/components/base/Button"
import ValidationResponse from "@/components/base/api/ValidationResponse"
import AuthFormHeader from "@/components/auth/AuthForm/AuthFormHeader"
export default {
    name: "VerifyEmailForm",
    components: { CodeInput, Button, ValidationResponse, AuthFormHeader },
    props: {
        email: {
            type: String,
            required: true,
        },
        password: {
            type: String,
            required: true,
        },
        length: {
            type: Number,
            default: 4,
        },
        polite: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        validationData: null,
        isLoadingNewCode: false,
    }),
    computed: {
        headline() {
            const du =
                "Wir haben Dir gerade eine E-Mail mit dem Bestätigungscode geschickt. Bitte trage den Code ein, um fortzufahren."
            const sie =
                "Wir haben Ihnen gerade eine E-Mail mit dem Bestätigungscode geschickt. Bitte tragen Sie den Code ein, um fortzufahren."
            return this.polite ? sie : du
        },
        hint() {
            const du =
                "Sieh in Deinem Ordner „Spam“ nach oder warte einige Minuten. Solltest Du weiterhin Probleme haben, kannst Du einen neuen Code anfordern."
            const sie =
                "Sehen Sie in Ihrem Ordner „Spam“ nach oder warten Sie einige Minuten. Sollten Sie weiterhin Probleme haben, können Sie einen neuen Code anfordern."
            return this.polite ? sie : du
        },
    },
    methods: {
        validateCode(code) {
            authService
                .verifyEmail(this.email, this.password, code)
                .then(() => {
                    this.$emit("success")
                })
                .catch((error) => {
                    this.validationData = error.response.data
                })
        },
        requestNewCode() {
            authService
                .requestEmailCode(this.email, this.password)
                .then((response) => {
                    this.validationData = response
                })
                .catch((error) => {
                    this.validationData = error.response.data
                })
        },
    },
}
