
import rules from "@/mixins/rules"
import Button from "@/components/base/Button"
import ValidationResponse from "@/components/base/api/ValidationResponse"
import authService from "@/services/auth.service"
import AuthFormHeader from "@/components/auth/AuthForm/AuthFormHeader"
export default {
    name: "PasswordForgottenForm",
    components: { ValidationResponse, Button, AuthFormHeader },
    mixins: [rules],
    props: {
        presetEmail: {
            default: "",
            type: String,
        },
    },
    data: () => ({
        validationData: null,
        email: "",
        isLoading: false,
        isLinkSent: false,
    }),
    created() {
        this.email = this.presetEmail
    },
    methods: {
        goBack() {
            this.$emit("back")
        },
        async resetPassword() {
            if (!this.$refs.form.validate() || this.isLoading) {
                return
            }
            this.isLoading = true
            authService
                .requestPasswordRest(this.email)
                .then((result) => {
                    this.validationData = result.data
                    this.isLinkSent = true
                })
                .catch((error) => {
                    this.validationData = error.response?.data
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
    },
}
