
import PhoneNumber from "@/components/base/input/PhoneNumber"
import AuthButtons from "@/components/auth/AuthButtons"
import Button from "@/components/base/Button"
import VerifyPhoneNumberForm from "@/components/auth/AuthForm/VerifyPhoneNumberForm"
import RegistrationForm from "@/components/auth/AuthForm/RegistrationForm"
import EmailLoginForm from "@/components/auth/AuthForm/EmailLoginForm"
import VerifyEmailForm from "@/components/auth/AuthForm/VerifyEmailForm"
import PasswordForgottenForm from "@/components/auth/AuthForm/PasswordForgottenForm"
import ValidationResponse from "@/components/base/api/ValidationResponse"

import traineeNavigationMixin from "@/mixins/traineeNavigationMixin"
import authService from "@/services/auth.service"
import rules from "@/mixins/rules"
import { routeToDashboard } from "@/helpers/routing"
import {
    COOKIE_LIFETIME_LONG,
    COOKIE_PREFERED_AUTH_MODE,
} from "@/enums/cookies"

export default {
    name: "AuthForm",
    components: {
        ValidationResponse,
        PasswordForgottenForm,
        VerifyEmailForm,
        EmailLoginForm,
        RegistrationForm,
        VerifyPhoneNumberForm,
        Button,
        AuthButtons,
        PhoneNumber,
    },
    mixins: [rules, traineeNavigationMixin],
    props: {
        goToProfileAfterLogin: {
            default: true,
            type: Boolean,
        },
        presetFirstname: {
            type: String,
            default: undefined,
        },
        presetLastname: {
            type: String,
            default: undefined,
        },
        allowFacebook: {
            type: Boolean,
            default: false, // Default: Disabled
        },
        allowGoogle: {
            type: Boolean,
            default: true,
        },
        allowApple: {
            type: Boolean,
            default: true,
        },
        allowPhone: {
            type: Boolean,
            default: true,
        },
        allowEmail: {
            type: Boolean,
            default: true,
        },
        headline: {
            type: String,
            default: "Einloggen oder registrieren",
        },
        socialRedirectUrl: {
            type: String,
            default: undefined,
        },
    },
    data: () => ({
        authMode: "phone",
        phoneNumber: "",
        email: "",
        password: "",
        otpCode: "",
        step: "authSelect",
        errorData: null,
        registrationPasswordRequired: false,
        registrationEmailRequired: true,
        accountExists: false,
        accountIsVerified: false,
        accountLoginMode: null,
        accountLoginRequestErrorAlert: false,
        accountLoginRequestError: "",
        isRegistrationPending: false,
        isRegistered: false,
        isInputDisabled: false,
    }),
    created() {
        this.authMode = this.$cookies.get(COOKIE_PREFERED_AUTH_MODE) || "phone"
    },
    methods: {
        setAuthMode(mode) {
            this.authMode = mode
            this.errorData = null
            this.$cookies.set(COOKIE_PREFERED_AUTH_MODE, mode, {
                maxAge: COOKIE_LIFETIME_LONG,
            })
        },
        async prepareLogin() {
            if (!this.$refs.form.validate()) {
                return
            }
            this.isInputDisabled = true
            const identifier =
                this.authMode === "phone" ? this.phoneNumber : this.email

            const response = await authService
                .checkUser(this.authMode, identifier)
                .finally(() => (this.isInputDisabled = false))
            this.accountExists = response.exists
            this.accountIsVerified = response.is_verified
            this.accountLoginMode = response.login_mode

            if (this.authMode === "phone") {
                this.requestPhoneLogin()
            }
            if (this.authMode === "email") {
                this.requestEmailLogin()
            }
        },
        requestEmailLogin() {
            if (this.accountExists) {
                this.$oabalytics.trackEvent("login_email")
                this.step = "emailLogin"
            } else {
                this.$oabalytics.trackEvent("register_email")
                this.registrationEmailRequired = false
                this.registrationPasswordRequired = true
                this.step = "register"
            }
        },
        async requestPhoneLogin() {
            this.$oabalytics.trackEvent("login_verify_phone")
            try {
                await authService.requestOTP(this.phoneNumber)
                this.step = "verifyPhoneNumber"
            } catch (error) {
                console.warn(error)
                this.errorData = error.response?.data
            }
        },
        onPasswordForgotten() {
            this.$oabalytics.trackEvent("login_password_forgotten")
            this.step = "passwordReset"
        },
        onPhoneVerificationSuccess(code) {
            this.otpCode = code
            this.loginWithPhoneNumber()
        },
        onEmailVerificationSuccess() {
            this.loginWithEmail()
        },
        onRegistrationSuccess(user) {
            if (this.isRegistrationPending || this.isRegistered) {
                return
            }
            this.isRegistrationPending = true
            this.password = user.password
            if (user.email) {
                this.email = user.email
            }
            this.$store
                .dispatch("account/registerLight", {
                    firstName: this.presetFirstname
                        ? this.presetFirstname
                        : user.firstname,
                    lastName: this.presetLastname
                        ? this.presetLastname
                        : user.lastname,
                    email: this.email,
                    password: this.password,
                    phoneNumber: this.phoneNumber,
                    otp: this.otpCode,
                    newsletterConfirmed: user.newsletter_confirmed,
                    registerByPhone: this.authMode === "phone",
                })
                .then(() => {
                    this.errorData = null
                    this.accountExists = true
                    if (this.authMode === "phone") {
                        this.loginWithPhoneNumber()
                    }
                    if (this.authMode === "email") {
                        this.step = "verifyEmail"
                    }
                    this.isRegistered = true
                })
                .catch((error) => {
                    this.errorData = error.response?.data
                })
                .finally(() => {
                    this.isRegistrationPending = false
                })
        },
        onEmailFormSuccess(password) {
            this.password = password
            this.$store
                .dispatch("account/login", {
                    email: this.email,
                    password: this.password,
                })
                .then(() => {
                    this.onLoginSuccess()
                })
                .catch((error) => {
                    let errorResponse = error.response?.data
                    if (errorResponse?.message === "Unverified") {
                        this.step = "verifyEmail"
                    } else {
                        this.errorData = errorResponse
                    }
                })
        },
        async loginWithPhoneNumber() {
            this.$store
                .dispatch("account/loginWithPhoneNumber", {
                    phoneNumber: this.phoneNumber,
                    otp: this.otpCode,
                })
                .then(() => {
                    this.onLoginSuccess()
                })
                .catch((error) => {
                    if (error.response.status === 404) {
                        this.$oabalytics.trackEvent("register_phone")
                        this.registrationEmailRequired = true
                        this.registrationPasswordRequired = false
                        this.step = "register"
                    }
                })
        },
        loginWithEmail() {
            this.$store
                .dispatch("account/login", {
                    email: this.email,
                    password: this.password,
                })
                .then(() => {
                    this.onLoginSuccess()
                })
                .catch((error) => {
                    if (error.response.status === 404) {
                        this.registrationEmailRequired = true
                        this.registrationPasswordRequired = false
                        this.step = "register"
                    }
                })
        },
        onLoginSuccess() {
            const redirectURL = this.$auth.$storage.getCookie("redirect_url")
            if (redirectURL) {
                this.$auth.$storage.removeCookie("redirect_url")
                if (redirectURL !== this.$route.fullPath) {
                    this.$router.push(redirectURL)
                }
                return
            }
            const routeTo = this.$route.query.to
            if (routeTo && typeof routeTo === "string") {
                this.$router.replace(this.$route.query.to)
                return
            }
            if (this.goToProfileAfterLogin) {
                const user = this.$auth.user
                if (user.school || user.region || user.business) {
                    this.$router.push(routeToDashboard(user))
                } else if (user.trainee?.is_profile_data_complete) {
                    this.$router.push(this.traineeHomeRoute)
                } else if (user.profile_path) {
                    /* Avoids duplicate routing to index page if profile path is empty */
                    this.$router.push(user.profile_path)
                }
            }
            this.$emit("success")
        },
    },
}
